body {
    font-family: 'Courier New';
    /* background-color: black;
    color: white; */
}

a {
    color: black;
    font-weight: bold;
}

.custom-section-container {
    margin-top: 22%;
}

.photo {
    width: 60%;
}

@media (min-width: 480px) {
    .custom-section-container {
        margin-top: 17%;
    }
    .photo {
        width: 50%;
    }
}

@media (min-width: 720) {
    .custom-section-container {
        margin-top: 10%;
    }
    .photo {
        width: 40%;
    }
}

@media (min-width: 1025px) {
    .custom-section-container {
        margin-top: 7%;
    }
    .photo {
        width: 20%;
    }
}
